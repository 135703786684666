interface ListDto<T, K> {
    id: T
    title: K
    lable: K
    icon: K
}

export class VipList {
    list: ListDto<number, string>[] = [
        {
            id: 1,
            title: 'AI面试',
            lable: 'Ai模拟面试',
            icon: 'icon-ambitus06'
        },
        {
            id: 2,
            title: '无限刷题',
            lable: '更好的体验',
            icon: 'icon-a-kaoshilianxi'
        },
        {
            id: 3,
            title: '下载',
            lable: '资源无限下载',
            icon: 'icon-xiazai'
        },
        {
            id: 4,
            title: '进阶',
            lable: '高阶题库',
            icon: 'icon-xiangshang'
        },
        {
            id: 5,
            title: '记录',
            lable: '错题等记录回顾',
            icon: 'icon-lishijilu_o'
        },
        {
            id: 6,
            title: '兑换',
            lable: '0元兑换',
            icon: 'icon-jifenduihuan'
        },
        {
            id: 7,
            title: '抽奖',
            lable: '抓宝机必中商品',
            icon: 'icon-youxi'
        },
        {
            id: 8,
            title: '积分',
            lable: '赠送1000积分',
            icon: 'icon-jifen'
        },
    ]
}


export interface TaskDto<T, K> {
    id: K,
    title: T,
    lable: T,
    icon: T,
    status?: null | K,
    btn_con: T,
    route: T
}
export class TaskList {
    list: TaskDto<string, number>[] = [
        {
            id: 1,
            title: '刷题赚积分',
            lable: '正确率越高，积分越高',
            icon: 'task/answer',
            status: null,
            route: 'question',
            btn_con: '去刷题'
        },
        {
            id: 2,
            title: '开通VIP',
            lable: '赠送500积分和一次抓宝机抽奖必中机会',
            icon: 'task/vip',
            status: null,
            route: 'vip_page',
            btn_con: '去开通'
        },
        {
            id: 3,
            title: '每日签到',
            lable: '每日一次签到获取10积分',
            icon: 'task/singin',
            status: null,
            route: 'center',
            btn_con: '去签到'
        },
        {
            id: 4,
            title: '绑定登录密码',
            lable: '绑定后可使用密码登录',
            icon: 'task/pass',
            status: null,
            route: 'setup',
            btn_con: '去绑定'
        },
        {
            id: 5,
            title: '积分兑好礼',
            lable: '只需积分，好礼免费邮到家',
            icon: 'task/goshop',
            status: null,
            route: 'conversion',
            btn_con: '去兑换'
        },
        {
            id: 6,
            title: '幸运大转盘',
            lable: '积分、好礼转不停',
            icon: 'task/game2',
            status: null,
            route: 'tiger',
            btn_con: '去抽奖'
        },
        {
            id: 7,
            title: '幸运抓宝机器',
            lable: '开通会员赠送一次必中的机会',
            icon: 'task/game1',
            status: null,
            route: 'turntable',
            btn_con: '去抽奖'
        }
    ]
}